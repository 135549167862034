/* :target::before {
    content: "";
    display: block;
    height: 2in;
    margin-top: -2.5in;
    visibility: hidden;
} */
.about {
    max-width: 15in;
    display: flex;
    height: 100%;
    /* margin: 0; */
    flex-direction: column;
    /* justify-content: flex-end; */
    justify-content: center;
    margin-top: 50px;
    padding-top: 100px;
    margin-bottom: 50px;
}

.about h1 {
    width: 100%;
    height: fit-content;
    font-size: xx-large;
}

.about .content {
    width: 100%;
    height: fit-content;
    margin: 0;
    display: flex;
    justify-content: center;
}


.about .content .paragraph {  
    font-size: large;   
    width: 100%; 
    margin-bottom: 100px;
}

.about .content .photo {
    width: 50%;
    /* background-image: url('../img_coroutine.png'); */
    background-color: red;
    width: 400px;
    height: 400px;
    margin-bottom: 100px;
}

.skill {
    
    /* background: red; */
    /* width: 200px; */
    /* font-size: large; */
}

.skills {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* background: red; */
    flex-wrap: nowrap;
}

.skills .progress {
    width: 90%;
    /* background-color: pink; */
}

.skills div {
    flex-basis: 100%;
}



.skill .val {
    float: right;
    /* background: blue; */
}

.progress {
    margin-bottom: 20px;
}

.progress .progress-bar-wrap {
    /* margin: 40px; */
}


:root{
--md-linear-progress-track-color: #a8a8a8;
--md-linear-progress-track-height: 5px;
--md-linear-progress-track-shape:	999px;
--md-linear-progress-active-indicator-color: #333;
--md-linear-progress-active-indicator-height: 5px;
}
.home {
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    max-height: 100vh;
    /* flex-basis: wrap; */
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: #333; */
    /* background-repeat: repeat; */
    /* background-size: 100% 100%; */
    padding-left: 200px;
    /* box-shadow: 0 6px 10px black; */
}

.home .content {
    /* width: 60%; */
    text-align: start;
    justify-content: center;
    height: 100%;
    display: flex;
    flex-direction: column; 
    align-items: start;
    line-height: 1;
}

.home .content .title {
    width: 100%;
    color: rgb(135, 135, 135);
    font-size: 2.5em;
}

.home .content .name {
    width: 100%;
    text-transform: uppercase;
    color: #eeeeee;
    font-size: 6.5em;
}

.home .content .summary {
    color: rgb(136, 136, 136);
    font-size: 2.5em;
    width: 100%;
}

.home .content .catch {
    width: 100%;
    color: rgb(136, 136, 136);
    font-size: 2.5em;

}

.App {
  width: 100%;
  height: 100vh;
  /* margin-left: 0; */
  margin: 0;
  display: flex;
  /* margin-right: 0; */
  background: linear-gradient(to top, #333, #3F3F3F);
  place-items: center;
  white-space: pre-line;
  flex-direction: column;
  justify-content: center;
}

.App .fixed-header {
  /* background-color: #333; */
  position: fixed;
  z-index: 999;
  background-attachment: fixed;
  background-color: rgba(0,0,0,0,0);
  top: 0;
  left: 0;
  right: 0;
}

.App .main-body {
  /* background: blue; */
  top: 0;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  height: 100%;
} 

.App .main-body .main-home {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
}


.App .main-body .content {
  top: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.App .main-body .footer {
  width: 100vw;
  margin: auto;
  padding: 0;
  height: 300px;
  background: #333;
  margin-top: 100px;
}


.App .main-body .footer .content {
  color: white;
  padding-top: 50px;
  /* display: flex;
  flex-direction: column; */
}

:root {
  --md-sys-color-primary: rgb(173, 173, 173);
  --md-sys-color-secondary: rgb(0, 0, 0);
  --md-filled-tonal-button-container-color: rgb(184, 184, 184);
  --md-filled-tonal-button-label-text-color: rgb(26, 26, 26);
}
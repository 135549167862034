.experience {
    margin-top: 100px;
    padding-top: 100px;
    margin: auto;
    max-width: 10in;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.experience .experiences {
    margin-top: 50px;
    display: grid;
    row-gap: 50px;
}

.experience .experiences .experience-detail {
    padding: 50px;
    background: #333;
    justify-content: center;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
}

.experience .experiences .experience-detail .title {
    width: 100%;
    color: #fff;
    font-size: larger;
    padding-bottom: 5px;
    display: flex;
    flex-direction: rows;
    border-bottom: 1px solid white;
}

.line {
    height: 200px;
    width: 10px;
    background: #333;
    padding: 0;
    margin: auto;
}

.experience .experiences .experience-detail p em {
    color: #eee;
    line-height: 1;
    font-size: large;
    /* background-color: blue; */
}

.experience .experiences .experience-detail p {
    color: #fff;
    line-height: 2;
    font-size: large;
}

.experience .experiences .experience-detail .title h2 {
    justify-content: center;
    width: fit-content;
    min-width: 310px;
    margin: 0;
    color: #fff;
}

.experience .experiences .experience-detail .title .tags {
    margin: 0;
    flex: 1;
    height: 100%;
    justify-content: right;
    align-items: center;
}
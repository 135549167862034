.tags {
    display: flex;
    width: 100%;
    padding-right: 10px;
    justify-content: end;
    /* background-color: blue; */
    padding-top: 4px;
    gap: 5px;
}

.tags .content {
    flex: 0;
    /* background-color: red; */
    white-space: nowrap;
    text-align: center;
    border: 1px solid #bbb;
    font-size: 14px;
    border-radius: 999px;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(87, 87, 87);
}

.blue-circle {
    width: 7px;
    height: 7px;
    background-color: #909090;
    margin-right: 5px;
    border-radius: 50%;
  }
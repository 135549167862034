body {
  font-family: 'Times New Roman';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: red; */
  /* background: linear-gradient(to top, #333, #fff); */
  /* line-height: 2; */
  margin: 0;
  width: 100%;
  /* background-color: #333; */
 }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Comic Sans MS',
    monospace;
}



html {
  scroll-behavior: smooth;
}
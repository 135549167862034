.mainheader {
    width: 100vw;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainheader .logo {
    width: 50px;
    height: 50px;
    align-self: left;
    background-size: 10px 10px;
    /* float: left; */
}

.mainheader .logo img {
    width: 50px;
    height: 50px;
    background-size: 10px 10px;
}

.mainheader .name {
    top: 0;
    bottom: 0;
    height: 1in;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #333;
    padding-right: 1in;
    color: #ffffff;
    text-align: right;
    box-shadow: 0 0 5px black;
}


.mainheader .menu {
    /* background: rgb(255, 255, 255); */
    /* top: 0; */
    margin-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    height: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 50px;
    width: fit-content;
    border-radius: 90px;
    box-shadow: 0px 1px 2px #959595;
}




.mainheader .menu a {
    font-size: 1.25em;
    color: #232323;
    text-align: center;
    height: fit-content;
    /* font-weight: lighter; */
    text-decoration: none;
}
